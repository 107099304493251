<template>
	<w-layout column fill-height @click="unselectProject()">
		<component :is="displayedComponent" :selected-project-id="selectedProjectId" :selected-service-id="selectedServiceId" />
	</w-layout>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'

export default {
	name: 'ProjectsList',
	components: {
		ProjectsListTable: () => ({
			component: import('@/components/Offers/CustomersManager/Lists/ProjectsListTable')
		}),
		ProjectsListTreeview: () => ({
			component: import('@/components/Offers/CustomersManager/Lists/ProjectsListTreeview')
		})
	},
	mixins: [ProjectsManagerModuleGuard],
	props: {
		selectedProjectId: {
			default: undefined,
			required: false,
			type: Number
		},
		selectedServiceId: {
			default: undefined,
			required: false,
			type: Number
		}
	},
	computed: {
		displayedComponent: function () {
			let contentDisplayPreference
			const contentDisplayMode = this.$store.state.customers.projects.display.content
			if (contentDisplayMode) {
				contentDisplayPreference = contentDisplayMode
			} else {
				const storedValue = localStorage.getItem('customer-offers__content-display')
				if (storedValue && ['table', 'treeview'].includes(storedValue)) {
					contentDisplayPreference = storedValue
				} else {
					contentDisplayPreference = 'table'
				}
				localStorage.setItem('customer-offers__content-display', storedValue)
				this.$store.dispatch('customers/projects/setContentDisplayMode', storedValue)
			}
			let result
			if (contentDisplayPreference === 'treeview') {
				result = 'ProjectsListTreeview'
			} else {
				result = 'ProjectsListTable'
			}
			return result
		}
	},
	methods: {
		unselectProject: function () {
			return this.appService.goTo({
				name: 'customer-services'
			})
		}
	}
}
</script>
